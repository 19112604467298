import { ListItemModel } from './ListItemModel';
import { IntegrationTypeEnum } from '../common/IntegrationTypeEnum';
import { IntegrationSelectionOption } from './IntegrationSelectionOption';

export type RootIntegrationSettings = CriteoIntegrationSettings &
  GaIntegrationSettings &
  AdwordsIntegrationSettings &
  GoogleAdsIntegrationSettings &
  TikTokAdsIntegrationSettings &
  GoogleSheetsIntegrationSettings &
  IikoWebIntegrationSettings &
  IikoIntegrationSettings &
  FbAdsIntegrationSettings &
  YandexLogsIntegrationSettings &
  YandexMarketIntegrationSettings &
  MyTargetIntegrationSettings &
  VkIntegrationSettings &
  Dv360IntegrationSettings &
  YandexMetricaIntegrationSettings &
  YandexDirectIntegrationSettings &
  AmoCrmIntegrationSettings &
  XAdsIntegrationSettings &
  EsputnikIntegrationSettings &
  AmplitudeIntegrationSettings &
  ActiveCampaignIntegrationSettings &
  BingAdsIntegrationSettings &
  MySqlIntegrationSettings &
  PostgreSqlIntegrationSettings &
  SocialBakersIntegrationSettings &
  SalesForceIntegrationSettings &
  FilesIntegrationSettings &
  WebhookAddonIntegrationSettings &
  WarehouseAddonIntegrationSettings &
  FbAdsAddonIntegrationSettings;

export class IntegrationSettingsBase {
  public Id: string | null = null;
  public Name: string | null = null;
  public TableName: string | null = null;
  public DateRange: Array<ListItemModel> | null = null;
  public Date?: { startDate: string; endDate: string } | null = null;
  public Schedule: Array<ListItemModel> | null = null;
  public OverwritePeriod: Array<ListItemModel> | null = null;
}

export interface CriteoIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
  BaseCurrencies: Array<ListItemModel> | null;
}

export interface GaIntegrationSettings {
  Account: Array<ListItemModel> | null;
  Property: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  Filters: Array<ListItemModel> | null;
}

export interface GoogleSheetsIntegrationSettings {
  Spreadsheets: Array<ListItemModel> | null;
  SourceTypes?: Array<ListItemModel> | null;
  Worksheets: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  HeaderRowNumber: number | null;
}

export interface AdwordsIntegrationSettings {
  CustomerAccounts: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface GoogleAdsIntegrationSettings {
  CustomerAccounts: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface TikTokAdsIntegrationSettings {
  CustomerAccounts: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}


export interface IikoWebIntegrationSettings {
  CustomerAccounts: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface IikoIntegrationSettings {
  CustomerAccounts: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface FbAdsIntegrationSettings {
  Account: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
  ActionReportTime: Array<ListItemModel> | null;
  AttributionSettings: Array<ListItemModel> | null;
  CustomAttributionClickWindow: Array<ListItemModel> | null;
  CustomAttributionViewWindow: Array<ListItemModel> | null;
}

export interface YandexLogsIntegrationSettings {
  Counter: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface YandexMarketIntegrationSettings {
  ByEventTime: Array<ListItemModel> | null;
  SpendingFilter: Array<ListItemModel> | null;
  ClickType: Array<ListItemModel> | null;
  Campaigns: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface MyTargetIntegrationSettings {
  Counter: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface VkIntegrationSettings {
  Account: Array<ListItemModel> | null;
  Clients: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface Dv360IntegrationSettings {
  Account: Array<ListItemModel> | null;
  Clients: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  Filters: Array<ListItemModel> | null;
}

export interface YandexMetricaIntegrationSettings {
  Counter: Array<ListItemModel> | null;
  Client: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  AttributionModel: Array<ListItemModel> | null;
}

export interface YandexDirectIntegrationSettings {
  ReportTypes: Array<ListItemModel> | null;
  Clients: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  IncludeVat: IntegrationSelectionOption | string | null;
  IncludeDiscount: IntegrationSelectionOption | string | null;
}

export interface AmoCrmIntegrationSettings {
  ReportTypes: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface XAdsIntegrationSettings {
  ReportTypes: Array<ListItemModel> | null;
  Attribution: Array<ListItemModel> | null;
  BindTo: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  UniqueOnly: Array<ListItemModel> | null;
}

export interface EsputnikIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
}

export interface AmplitudeIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
}

export interface BingAdsIntegrationSettings {
  Accounts: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  ReportTypes: Array<ListItemModel> | null;
}

export interface ActiveCampaignIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
}

export interface MySqlIntegrationSettings {
  Database: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface GetintentSettings {
  Parameters: Array<ListItemModel> | null;
}

export interface PostgreSqlIntegrationSettings {
  Database: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface SocialBakersIntegrationSettings {
  ReportTypes: Array<ListItemModel> | null;
  SourceType: Array<ListItemModel> | null;
  Profiles: Array<ListItemModel> | null;
  Posts: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
}

export interface SalesForceIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
}

export interface FilesIntegrationSettings {
  Parameters: Array<ListItemModel> | null;
  Types: Array<ListItemModel> | null;
}

export interface WebhookAddonIntegrationSettings {
  EventTypes: Array<ListItemModel> | null;
  EventNames: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  Filters: Array<ListItemModel> | null;
  Output: Array<{ parameter: string, value: string }>| null,
}

export interface WarehouseAddonIntegrationSettings {
  HitsTableName: string | null,
  SessionsTableName: string | null
}

export interface FbAdsAddonIntegrationSettings {
  EventTypes: Array<ListItemModel> | null;
  EventNames: Array<ListItemModel> | null;
  Parameters: Array<ListItemModel> | null;
  Properties: Array<ListItemModel> | null;
  Filters: Array<ListItemModel> | null;
  Output: Array<{ parameter: string, value: string }>| null,
  FacebookPixelEventName: Array<ListItemModel> | null;
  ActionSource: Array<ListItemModel> | null;
  Accounts: Array<ListItemModel> | null;
  FbPixel: Array<ListItemModel> | null;
  TestEventCode: string | null;
}

export class IntegrationSettings extends IntegrationSettingsBase {
  public [IntegrationTypeEnum.criteo]?: CriteoIntegrationSettings | null;
  public [IntegrationTypeEnum.facebook_ads]?: FbAdsIntegrationSettings | null;
  public [IntegrationTypeEnum.myTarget]?: MyTargetIntegrationSettings | null;
  public [IntegrationTypeEnum.vk_ads]?: VkIntegrationSettings | null;
  public [IntegrationTypeEnum.dv360]?: Dv360IntegrationSettings | null;
  public [IntegrationTypeEnum.amocrm]?: AmoCrmIntegrationSettings | null;
  public [IntegrationTypeEnum.ga]?: GaIntegrationSettings | null;
  public [IntegrationTypeEnum.google_sheets]?: GoogleSheetsIntegrationSettings | null;
  public [IntegrationTypeEnum.adwords]?: AdwordsIntegrationSettings | null;
  public [IntegrationTypeEnum.google_ads]?: GoogleAdsIntegrationSettings | null;
  public [IntegrationTypeEnum.tiktok_ads]?: TikTokAdsIntegrationSettings | null;
  public [IntegrationTypeEnum.iikoweb]?: IikoWebIntegrationSettings | null;
  public [IntegrationTypeEnum.iiko]?: IikoIntegrationSettings | null;
  public [IntegrationTypeEnum.yandex_metrica_logs_api]?: YandexLogsIntegrationSettings | null;
  public [IntegrationTypeEnum.yandexMarket]?: YandexMarketIntegrationSettings | null;
  public [IntegrationTypeEnum.yandex_metrica]?: YandexMetricaIntegrationSettings | null;
  public [IntegrationTypeEnum.yandex_direct]?: YandexDirectIntegrationSettings | null;
  public [IntegrationTypeEnum.x_ads]?: XAdsIntegrationSettings | null;
  public [IntegrationTypeEnum.eSputnik]?: EsputnikIntegrationSettings | null;
  public [IntegrationTypeEnum.amplitude]?: AmplitudeIntegrationSettings | null;
  public [IntegrationTypeEnum.activeCampaign]?: ActiveCampaignIntegrationSettings | null;
  public [IntegrationTypeEnum.bing]?: BingAdsIntegrationSettings | null;
  public [IntegrationTypeEnum.mySql]?: MySqlIntegrationSettings | null;
  public [IntegrationTypeEnum.getintent]?: GetintentSettings | null;
  public [IntegrationTypeEnum.postgreSql]?: PostgreSqlIntegrationSettings | null;
  public [IntegrationTypeEnum.socialBakers]?: SocialBakersIntegrationSettings | null;
  public [IntegrationTypeEnum.salesforce]?: SalesForceIntegrationSettings | null;
  public [IntegrationTypeEnum.file]?: FilesIntegrationSettings | null;
  public [IntegrationTypeEnum.webhookAddon]?: WebhookAddonIntegrationSettings | null;
  public [IntegrationTypeEnum.warehouseAddon]?: WarehouseAddonIntegrationSettings | null;
  public [IntegrationTypeEnum.fbAdsAddon]?: FbAdsAddonIntegrationSettings | null;
}
