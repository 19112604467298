import { AfterViewInit, Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ISettingsServiceBase } from '../../services/integrationSettings/IEtlSettingsService';

import { SETTINGS_SERVICE_BASE } from '../../services/DiExtensions';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsComponentFactoryResolver } from '../../services/settingsComponentFactory';
import { IntegrationService } from '../../services/integration.service';
import { AppGlobalPreloaderService } from '../../../../services/app-global-preloader.service';
import { ErrorMessage } from '../../../../models/common/ErrorMessage';
import { IntegrationInfoViewModel } from '../../../../models/viewModels/CreateIntegrationViewModel';
import { Observable } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import {ProfileService} from "../../../../services/profile.service";

@Component({
  template: '',
  styleUrls: ['./integration-settings.component.css'],
})
export abstract class IntegrationSettingsComponent implements AfterViewInit {
  @ViewChild('integrationSettings', { read: ViewContainerRef })
  public integrationSettings: ViewContainerRef;

  @ViewChild('integrationSourceDestination', { read: ViewContainerRef })
  public integrationSourceDestination: ViewContainerRef;

  @ViewChild('integrationInfo', { read: ViewContainerRef })
  public integrationInfo: ViewContainerRef;

  @ViewChild('integrationSchedule', { read: ViewContainerRef })
  public integrationSchedule: ViewContainerRef;

  public commonErrorMessage: string;
  public saveButton: string = 'Save';
  public blockedPanel: boolean = false;
  public createMode: boolean = true;
  public canSave: Observable<boolean>;
  public get isEtl(): boolean {
    return this.settingsService?.isETL() || false;
  }

  protected userName: string;
  protected userEmail: string;
  protected settingsService: ISettingsServiceBase;
  private integrationSettingsComponentRef: ComponentRef<any>;
  private integrationSourceDestinationComponentRef: ComponentRef<any>;
  private integrationInfoComponentRef: ComponentRef<any>;
  private integrationScheduleComponentRef: ComponentRef<any>;
  private integrationInfoData: IntegrationInfoViewModel;

  protected constructor(
    protected readonly integrationService: IntegrationService,
    protected readonly settingsComponentFactoryResolver: SettingsComponentFactoryResolver,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly appGlobalPreloaderService: AppGlobalPreloaderService,
    protected readonly userService: UserService,
    protected readonly profileService: ProfileService
  ) {
    this.profileService.getEmail().subscribe(email => this.userEmail = email);
    this.profileService.getName().subscribe(name => this.userName = name);
  }

  public ngAfterViewInit(): void {
    this.integrationInfoData = this.integrationService.getIntegrationInfo();
    const integrationSettingsInjector = this.settingsComponentFactoryResolver.getInjector(this.integrationInfoData);
    this.settingsService = integrationSettingsInjector.get(SETTINGS_SERVICE_BASE);
    const integrationBody = this.settingsService.isETL() ?
      this.settingsComponentFactoryResolver.getParametersComponentFactory(
      this.integrationInfoData.integrationSourceType.integrationType
    ) : this.settingsComponentFactoryResolver.getAddonSettingsComponentFactory(
      this.integrationInfoData.integrationDestinationType.integrationType
    );

    const integrationInfo = this.settingsComponentFactoryResolver.getCreateIntegrationInfo();
    const schedule = this.settingsComponentFactoryResolver.getScheduleComponentFactory(
      this.integrationInfoData.integrationSourceType.integrationType
    );

    this.settingsService.connectToLoading().subscribe((res: boolean): void => {
      setTimeout((): void => {
        this.blockedPanel = res;
      });
    });

    this.canSave = this.settingsService.isCanSave();

    setTimeout((): void => {
      this.integrationSettingsComponentRef = this.integrationSettings.createComponent(integrationBody, 0, integrationSettingsInjector);
      this.integrationInfoComponentRef = this.integrationInfo.createComponent(integrationInfo, 0, integrationSettingsInjector);
      if (this.settingsService.isETL()) {
        this.integrationScheduleComponentRef = this.integrationSchedule.createComponent(schedule, 0, integrationSettingsInjector);
      }
    });

    if (!this.createMode ) {
      const sourceDestination = this.settingsComponentFactoryResolver.getSourceDestinationFactory();
      setTimeout((): void => {
       this.integrationSourceDestinationComponentRef = this.integrationSourceDestination.createComponent(sourceDestination, 0, integrationSettingsInjector);
      });
    }
  }

  public createIntegration(): void {
    this.blockedPanel = true;
    this.appGlobalPreloaderService.turnOnGlobalPreloader();
    const prevButtonText = this.saveButton;
    this.saveButton = 'Validation...';
    this.commonErrorMessage = null;
    const integrationSettings = this.settingsService.getSelectedSettings();

    this.integrationService.saveIntegration(integrationSettings, this.settingsService.getSettingsName(), this.settingsService.isETL()).subscribe((saveResult: Array<ErrorMessage>): void => {
      this.blockedPanel = false;
      this.appGlobalPreloaderService.turnOffGlobalPreloader();
      this.saveButton = prevButtonText;

      if (saveResult == null) {
        if (this.createMode){
          (window as any).analytics.track('Integration created', {
            source:  this.integrationInfoData.integrationSourceType.title,
            destination: this.integrationInfoData.integrationDestinationType.title,
            userId: this.userService.getUserId(),
            name: this.userName,
            email: this.userEmail
          });
        }

        this.router.navigate(['list'], { relativeTo: this.activatedRoute }).then();
        return;
      }

      const commonErrorMessage = saveResult.find((f: ErrorMessage): boolean => f.message === 'common')?.userMessage;

      if (commonErrorMessage) {
        this.commonErrorMessage = commonErrorMessage;
      }

      this.settingsService.setErrors(saveResult);
    });
  }

  public cancelSettings(): void {
    this.router.navigate(['integrations']).then();
  }

  public abstract getPageTitle(): string;

  public abstract getPageSubtitle(): string;
}
