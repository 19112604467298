import { NavigationEnd, Router } from '@angular/router';
import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from '../../services/user.service';
import { ProfileService } from '../../services/profile.service';
import { MenuPopupModel } from '../../modules/shared/models/MenuPopupModel';
import { MenuItemRoleEnum } from '../../models/common/MenuItemRoleEnum';
import { HeaderAvaComponent } from '../../modules/shared/components/menu-popup-custom-componants/header-ava/header-ava.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input()
  public Visible: boolean;

  public menuPopupModel: MenuPopupModel = {};
  public avatarImg: Observable<string> = new Observable<string>();
  public avatarLoading: Observable<boolean> = new Observable<boolean>();
  public integrationsNavActive: boolean = false;
  public createNavActive: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private readonly profileService: ProfileService,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  public ngOnInit(): void {
    this.setPopupMenu();
    this.avatarImg = this.profileService.getAvatarUrl();
    this.avatarLoading = this.profileService.getAvatarLoading();
    this.router.events.pipe(filter((event: any): boolean => event instanceof NavigationEnd)).subscribe((e: NavigationEnd): void => {
      const segments = e.urlAfterRedirects.split('/');
      const state = segments.slice(1, 3).join(':');
      this.setSelectedNavElement(state);
    });
  }

  public connections(): void {
    throw new DOMException('Not implemented');
  }

  public createNew(): void {
    sessionStorage.removeItem('integrationInfoViewModel');
    this.router.navigate(['integrations', 'create', 'source']).then();
    this.integrationsNavActive = false;
    this.createNavActive = true;
  }

  public integrations(): void {
    this.router.navigate(['integrations', 'list']).then();
    this.integrationsNavActive = true;
    this.createNavActive = false;
  }

  private setSelectedNavElement(state: string): void {
    switch (state) {
      case 'integrations:create':
        {
          this.integrationsNavActive = false;
          this.createNavActive = true;
        }
        break;
      case 'integrations:list':
        {
          this.integrationsNavActive = true;
          this.createNavActive = false;
        }
        break;
      default: {
        this.integrationsNavActive = false;
        this.createNavActive = false;
      }
    }
  }

  private setPopupMenu(): void {
    this.menuPopupModel.headerComponent = this.componentFactoryResolver.resolveComponentFactory(HeaderAvaComponent);
    this.menuPopupModel.classList = 'left-top more-action-popup-menu anime-slide-left';
    this.menuPopupModel.top = 14;
    this.menuPopupModel.left = 50;
    this.menuPopupModel.width = 256;
    this.menuPopupModel.menuItems = [
      {
        type: MenuItemRoleEnum.divider
      },
      {
        label: 'Profile settings',
        routerLink: '/manage/profile'
      },
      {
        label: 'Billing',
        routerLink: '/manage/billing'
      },
      {
        type: MenuItemRoleEnum.divider
      },
      {
        label: 'Documentation',
        iconRight: 'renta-ic-relogin',
        showIconOnHover: true,
        link: 'https://renta.im/docs/'
      },
      {
        type: MenuItemRoleEnum.divider
      },
      {
        label: 'Log Out',
        command: (): void => {
          localStorage.clear();
          sessionStorage.clear();
          this.userService.logOut();
        }
      }
    ];
  }
}
