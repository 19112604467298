<div class='height-fix'>
  <p-progressBar mode='indeterminate' styleClass='modal-preloader' *ngIf='isLoading'></p-progressBar>
</div>
<div class='modal-token-wrapper p-d-flex p-jc-start p-ai-center p-flex-column width-max p-py-5 p-px-6'>

  <div class='p-mt-3 p-mb-2 p-d-flex p-flex-column p-ai-center '>
    <span class='renta-font-big p-text-bold'>Configure Javascript source</span>
  </div>

  <div *ngIf='!isLoaded' class='p-mt-3'>
    <i class='drop-down-icon pi pi-spin pi-spinner'></i>
  </div>

  <div class='p-d-flex p-flex-column streaming-from-container' *ngIf='isLoaded'>
    <div class='integration-settings-container modal-token-form-container  p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-d-flex'>
        <div class='http-container'>
          <app-renta-dropdown
            [CanSearch]='false'
            (OnApply)='onApplyProtocol($event)'
            [ShowName]='true'
            [RentaDropdownSource]='ProtocolDataSource'
          ></app-renta-dropdown>
        </div>
        <div class='p-pt-5 width-max p-pl-1'>
          <app-renta-input [RentaInputSource]='SiteUrlDataSource' [IsShowName]='false'
                           (OnApply)='onApplySiteUrl($event)'></app-renta-input>
        </div>
      </div>
    </div>

    <div class='integration-settings-container modal-token-form-container p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-d-flex width-max switcher-row'>
        <div class='p-d-flex p-flex-column description'>
          <span class='renta-font-normal p-text-bold'>Server-side implementation</span>
          <span class='renta-font-small renta-subtext-color'>Add A-record to your DNS management service.</span>
        </div>
        <div class='p-d-flex p-jc-end p-ai-center switcher'>
          <app-renta-switcher
            [Disabled]='isLoading'
            [IsChecked]='serverSideImplementation'
            (OnSwitcherChange)='onSwitcherChange($event)'
          ></app-renta-switcher>
        </div>
      </div>
      <div class='server-side-container' *ngIf='serverSideImplementation'>
        <div *ngIf='serverSideImplementation'>
          <div class='p-d-flex width-max p-ai-start p-jc-between '>
            <div class='width-max p-pb-1'>
              <app-renta-input [RentaInputSource]='ARecordDataSource'
                               (OnApply)='onApplyCustomDomain($event)'></app-renta-input>
            </div>
            <div class='p-as-center arrow-container'>
              <i class="renta-ic renta-ic-arrow"></i>
            </div>
            <div class='width-max ip-address-container'>
              <app-renta-input [RentaInputSource]='IpAddressDataSource'></app-renta-input>
              <div class='copy-ipaddress-btn'>
                <i
                  class='pi pi-clone mr-l-5 renta-placeholder-color copy'
                  (click)='onCopyIpAddress()'
                ></i>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='integration-settings-container modal-token-form-container p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-d-flex p-flex-column'>
        <span class='renta-font-normal p-text-bold'>Installing Code</span>
        <span class='renta-font-small renta-subtext-color'>{{installingPlaceholder}}</span>

        <div class='installing-code-container'>
          <div class='installing-code-border-wrapper'>
            <div class='installing-code-wrapper'>
              <span class='renta-font-small renta-placeholder-color'>{{installingJavaScript}}</span>
            </div>
          </div>
          <div class='copy-code-btn'>
            <i
              class='pi pi-clone mr-l-5 renta-placeholder-color copy'
              (click)='onCopyCode()'
            ></i>

          </div>
        </div>
      </div>
    </div>

    <div class='integration-settings-container modal-token-form-container p-d-flex p-flex-column p-px-6 p-py-4'>
      <div class='p-d-flex width-max switcher-row'>
        <div class='p-d-flex p-flex-column description'>
          <span class='renta-font-normal p-text-bold'>Use excluded domains</span>
          <span
            class='renta-font-small renta-subtext-color'>Add excluded domains for filter data in destination table.</span>
        </div>
        <div class='p-d-flex p-jc-end p-ai-center switcher'>
          <app-renta-switcher
            [Disabled]='isLoading'
            [IsChecked]='useExcludedDomains'
            (OnSwitcherChange)='onSwitcherSessionTableChange($event)'
          ></app-renta-switcher>
        </div>
      </div>
      <div class='setting-row-wrapper' *ngIf='useExcludedDomains'>
        <div *ngIf='isLoaded;'>
          <app-renta-input-string-array
            [HideName]='true'
            [RentaInputStringArrayDataSource]='ExcludedDomainsDataSource'
            (OnApply)='onApplyExcludedDomains($event)'></app-renta-input-string-array>
        </div>
      </div>
    </div>

    <div class='p-d-flex p-justify-end width-max p-mt-auto p-pt-2' *ngIf='isLoaded'>
      <p-button
        styleClass='modal-btn renta-btn-secondary close-btn'
        type='submit'
        label='close'
        [disabled]='isLoading'
        (onClick)='onClose()'>
      </p-button>
      <p-button
        styleClass='modal-btn renta-btn-primary create-btn-wrapper'
        type='submit'
        label='{{ editTokenId ? "update" : "create" }}'
        [disabled]='isLoading'
        (onClick)='create()'>
      </p-button>
    </div>
  </div>
</div>
