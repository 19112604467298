
export enum IntegrationTypeEnum {
  yandex_direct = 'yandex_direct',
  adwords = 'adwords',
  google_ads = 'google_ads',
  tiktok_ads = 'tiktok_ads',
  iikoweb = 'iikoweb',
  iiko = 'iiko',
  ga = 'ga4',
  google_sheets = 'google_sheets',
  activeCampaign = 'activeCampaign',
  yandex_metrica_logs_api = 'yandex_metrica_logs_api',
  facebook_ads = 'facebook_ads',
  adform = 'adform',
  gaStream = 'gaStream',
  myTarget = 'myTarget',
  vk_ads = 'vk_ads',
  x_ads = 'x_ads',
  eSputnik = 'eSputnik',
  yandex_metrica = 'yandex_metrica',
  bing = 'bing',
  amplitude = 'amplitude',
  yandexMarket = 'yandexMarket',
  file = 'file',
  socialBakers = 'socialBakers',
  criteo = 'criteo',
  amocrm = 'amocrm',
  dv360 = 'dv360',
  getintent = 'getintent',
  salesforce = 'salesforce',
  bigQuery = 'bigquery',
  clickhouse = 'clickhouse',
  amazon_redshift = 'amazon_redshift',
  snowflake = 'snowflake',
  postgreSql = 'postgreSql',
  mySql = 'mySql',
  sql_server = 'sql_server',
  javascript_sdk = 'javascript_sdk',
  webHook = 'webhook',
  webhookAddon = 'webhookAddon',
  fbAdsAddon = 'fbAdsAddon',
  warehouseAddon = 'warehouseAddon',
  amazon_s3 = 'amazon_s3',
  kommo = 'kommo'
}

export enum IntegrationGroupEnum {
  integration = 'integration',
  addon = 'addon',
}
