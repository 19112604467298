import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { delay, map } from 'rxjs/operators';
import { RentaApiService } from '../../../../../services/renta-api.service';

import { BillingPayload } from '../models/requests/BillingPayload';
import { BillingView, PlanView } from '../models';
import { InvoicesModel } from '../../../models/InvoicesModel';
import { Token } from '@stripe/stripe-js';
import { ApiResponse } from '../../../../../models/common/ApiResponse`T';

export const TRIAL = 'Trial';
export const ENTERPRISE = 'Enterprise';

@Injectable()
export class BillingService {
  public isCardFormValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public IsTrial: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private SelectedUserPlan: PlanView;
  constructor(private rentaApiService: RentaApiService) {}

  public getBillingInfo(): Observable<BillingView> {
    return this.rentaApiService.getBillingInfo().pipe(
      delay(500),
      map((res: BillingPayload): BillingView => {
        const currPlan = res.plans.find(f => f.selected);
        currPlan.state = res.state;
        const result = {
          dueDate: res.dueDate,
          selectedPlan: {
            planInfo: currPlan,
            dueDate: res.dueDate,
            paymentMethod: res.paymentMethod,
            quotaRows: res.quotaRows,
            usedRows: res.usedRows,
            overLimitInvoicePrice: res.overLimitInvoicePrice
          },
          quotaRows: res.quotaRows,
          plans: res.plans
        }
        this.IsTrial.next(currPlan.id === TRIAL);

        return result;
      })
    );
  }

  public checkout(plan: string): Observable<{ clientSecret?: string; error?: string, paymentLink?: string }> {
    return this.rentaApiService.checkout({ plan });
  }

  public confirmCheckout(plan: string): Observable<string> {
    return this.rentaApiService.confirmCheckout(plan);
  }

  public setSelectedPlan(selectedPlan: PlanView): void {
    this.SelectedUserPlan = selectedPlan;
  }

  public getSelectedPlan(): PlanView {
    return this.SelectedUserPlan;
  }

  public setCardError(isValid: boolean): void {
    this.isCardFormValid.next(isValid);
  }

  public onCardError(): Observable<boolean> {
    return this.isCardFormValid.asObservable();
  }

  public cancelSubscription(): Observable<{status: boolean, link:string}> {
    return this.rentaApiService.cancelSubscription();
  }

  public loadInvoices(first: number, rows: number): Observable<Array<InvoicesModel>> {
    return this.rentaApiService.getInvoices(first, rows);
  }

  public  updateInvoicesData(body: {country: string, city: string, zip: string, unit: string, address: string, companyName: string  }): Observable<boolean> {
  return this.rentaApiService.updateInvoicesData(body);
}
  public renewSubscription(plan: string): Observable<{status: boolean, link:string}> {
    return this.rentaApiService.renewSubscription(plan);
  }

  public updatePaymentCard(token: Token): Observable<ApiResponse<any>> {
    return this.rentaApiService.updatePaymentCard(token);
  }
}
