import { Injectable } from '@angular/core';
import { UserEventType } from '../models/UserEventTypes';
import { UserEvent } from '../models/events/user/UserEvent';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {

  constructor() {
  }

  public onEmit(eventMessage: UserEvent): void {
    const { eventType } = eventMessage;

    switch (eventType) {
      case UserEventType.UserRegistered: {
        (window as any).analytics.track('Registration', {
              userId: eventMessage.userId,
              email: eventMessage.email,
              name: eventMessage.name,
              registration_type: eventMessage.type,
            });
        break;
      }
      case UserEventType.UserLogin: {
        (window as any).analytics.identify(eventMessage.userId, {
          userId: eventMessage.userId,
          email: eventMessage.email,
          name: eventMessage.name,
          registration_type: eventMessage.type,
        });
        break;
      }
    }
  }
}
