import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {UserInfo} from "../models/user/UserInfo";

@Injectable({
  providedIn: 'root',
})
export class UserDataIntercomService {
  private readonly userData: { email: string, userId: string } = { email: '', userId: '' };
  private readonly userInfo:  UserInfo;

  constructor(private readonly storageService: StorageService) {
    try {
      this.userInfo = this.storageService.localGetItem("userInfo");
      if (this.userInfo) {
        const { userEmail , userId } = this.userInfo;

        (window as any).dataLayer.push({
          email: userEmail,
          userId,
        });
      }
      } catch (error) {
      console.error('Error getting user info:', error);
    }
  }

  public setUserData(email: string, userId: string): void {
    this.userData.email = email;
    this.userData.userId = userId;
    (window as any).dataLayer.push(this.userData);
  }
}
