import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import { fadeTrigger } from '../../../../animations/fade.animation';
import { ApiResponse } from '../../../../models/common/ApiResponse`T';
import { UserInfo } from '../../../../models/user/UserInfo';
import * as _ from 'lodash';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Title } from '@angular/platform-browser';
import { delay, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

export enum AuthState {
  SignIn = '/auth/sign-in',
  SignUp = '/auth/sign-up',
  ForgotPassword = '/auth/forgot-password',
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['../common-auth-styles.css', './sign-in.component.css'],
  animations: [fadeTrigger],
})
export class SignInComponent implements OnInit {
  public AuthState: typeof AuthState = AuthState;
  public userCredentials: FormGroup;
  public errorMessage: string = null;
  public authState: AuthState;
  public submitText: string = '';
  public authInProgress: boolean = false;
  public submitted: boolean = false;
  public showSuccessCheckmark: boolean = false;
  public checkmarkDescription: string;
  public checkmarkTitle: string;
  public UIRefresh: boolean = true;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private recaptchaV3Service: ReCaptchaV3Service,
    private titleService: Title
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle("Sign in | Renta");
    this.userCredentials = new FormGroup(
      {
        email: new FormControl('', {
          validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        name: new FormControl('', [Validators.required]),
      },
      { updateOn: 'submit' }
    );

    this.checkState(this.location.path());
    this.location.onUrlChange((url: string): void => this.onUrlChange(url));
  }

  public onSubmit(): void {
    this.submitted = true;
    this.errorMessage = null;
    if (!this.isFormValid()) {
      return;
    }

    const path = this.location.path();

    this.recaptchaV3Service.execute(this.submitText.replace(' ', ''))
      .subscribe((token) => {
        switch (path) {
          case AuthState.SignIn:
            this.onSignIn(token);
            break;
          case AuthState.SignUp:
            this.onSignUp(token);
            break;
          case AuthState.ForgotPassword:
            this.onForgot(token);
            break;
        }
      });
  }

  public isFormValid(): boolean {
    if (this.authInProgress) {
      return true;
    }

    this.getErrorMessage('name');
    this.getErrorMessage('email');
    this.getErrorMessage('password');

    switch (this.authState) {
      case AuthState.ForgotPassword:
        return this.userCredentials.get('email').valid;
      case AuthState.SignIn:
        return this.userCredentials.get('email').valid && this.userCredentials.get('password').valid;
      case AuthState.SignUp:
        return (
          this.userCredentials.get('email').valid && this.userCredentials.get('password').valid && this.userCredentials.get('name').valid
        );
    }
  }

  public toForgotPassword(): void {
    this.refreshUI();

    if (this.authInProgress) {
      return;
    }
    this.titleService.setTitle("Forgot | Renta");
    this.location.go(AuthState.ForgotPassword);
  }

  public toSignUp(): void {
    this.refreshUI();

    if (this.authInProgress) {
      return;
    }

    this.titleService.setTitle("Sign up | Renta");
    this.location.go(AuthState.SignUp);
  }

  public toSignIn(): void {
    this.refreshUI();

    this.showSuccessCheckmark = false;
    if (this.authInProgress) {
      return;
    }

    this.titleService.setTitle("Sign in | Renta");
    this.location.go(AuthState.SignIn);
  }

  public onSubmitGoogle(): void {
    window.location.replace(environment.signInUrl + environment.googleAuth);
  }

  public getErrorMessage(inputName: string): string {
    const input = this.userCredentials.get(inputName);

    // tslint:disable-next-line:forin
    for (const name in input.errors) {
      console.log(inputName, name);
    }

    return null;
  }

  private checkState(path: string): void {
    switch (path) {
      case AuthState.SignIn:
        this.authState = AuthState.SignIn;
        this.submitText = 'Sign In';
        break;
      case AuthState.SignUp:
        this.authState = AuthState.SignUp;
        this.submitText = 'Sign Up';
        break;
      case AuthState.ForgotPassword:
        this.authState = AuthState.ForgotPassword;
        this.submitText = 'Reset Password';
        break;
    }
  }

  private onSignIn(token: string): void {
    const email = this.userCredentials.get('email').value;
    const password = this.userCredentials.get('password').value;

    this.userCredentials.get('email').disable();
    this.userCredentials.get('password').disable();
    this.authInProgress = true;

    this.signIn(email, password, token);
  }

  private onSignUp(recapchaToken: string): void {
    const name = this.userCredentials.get('name').value;
    const email = this.userCredentials.get('email').value;
    const password = this.userCredentials.get('password').value;

    this.userCredentials.get('name').disable();
    this.userCredentials.get('email').disable();
    this.userCredentials.get('password').disable();
    this.authInProgress = true;

    this.userService.signUp(email, password, name, recapchaToken).subscribe((res: ApiResponse<UserInfo>): void => {
      this.authInProgress = false;
      if (res.baseResponse.status) {
        this.userCredentials.reset();
        this.showSuccessCheckmark = true;
        this.checkmarkTitle = 'Registration successfully!';
        this.checkmarkDescription = 'Confirm your Email. The link has been sent to your email.';

        (window as any).dataLayer.push({
          userId: res.result.userId,
          email,
          name
        });

        setTimeout((): void => {
          this.signIn(email, password, null, true);
        }, 5000);

        this.userCredentials.get('name').enable();
        this.userCredentials.get('email').enable();
        this.userCredentials.get('password').enable();

        return;
      }

      this.userCredentials.get('name').enable();
      this.userCredentials.get('email').enable();
      this.userCredentials.get('password').enable();

      this.errorMessage = _.some(res.baseResponse.errors) ? res.baseResponse.errors[0].userMessage : "Something was wrong";
    });
  }

  private onForgot(recapchaToken: string): void {
    const email = this.userCredentials.get('email').value;

    this.userCredentials.get('email').disable();
    this.authInProgress = true;

    this.userService.forgotPassword(email, recapchaToken)
      .pipe(
        finalize(() => {
          this.authInProgress = false;
          this.userCredentials.get('email').enable();
        })
      )
      .subscribe({
        next: (res: string): void => {
          this.userCredentials.reset();

          if (res === null) {
            this.showSuccessCheckmark = true;
            this.checkmarkTitle = 'Check your email!';
            this.checkmarkDescription = 'Password recovery request successfully sent.';
            of(null).pipe(delay(2000)).subscribe(() => this.toSignIn());
          } else {
            this.errorMessage = res;
          }
        },
        error: (err: any): void => {
          this.errorMessage = 'An error occurred. Please try again.';
          console.error('Password recovery error:', err);
        }
      });
  }


  private onUrlChange(url: string): void {
    this.submitted = false;
    this.errorMessage = null;
    this.userCredentials.reset();

    if (this.authInProgress) {
      return;
    }

    this.checkState(url);
  }

  private refreshUI(): void {
    this.UIRefresh = !this.UIRefresh;
    setTimeout((): void => {
      this.UIRefresh = !this.UIRefresh;
    });
  }

  private signIn(email: string, password: string, recapchaToken: string, isSignUp: boolean = false): void {
    this.userService.signIn(email, password, recapchaToken, isSignUp).subscribe((res: string): void => {
      if (res === null) {
        const redirectUri: string = this.activatedRoute.snapshot.params.redirectUri;
        if (redirectUri !== null && redirectUri !== undefined) {
          this.router.navigateByUrl(redirectUri.replace(location.origin, '')).then();
        }

        this.router.navigateByUrl('/').then();
      } else {
        this.authInProgress = false;
        this.userCredentials.get('email').enable();
        this.userCredentials.get('password').enable();

        this.errorMessage = res;
      }
    });
  }
}
