import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {IntegrationTypeEnum} from '../../../models/common/IntegrationTypeEnum';
import {
  CriteoSettingsComponent
} from '../components/internal/integration-settings/criteo-settings/criteo-settings.component';
import {CriteoSettingsService} from './integrationSettings/criteo-settings.service';
import {ETL_SETTINGS_SERVICE, SETTINGS_SERVICE_BASE} from './DiExtensions';
import {GaSettingsComponent} from '../components/internal/integration-settings/ga-settings/ga-settings.component';
import {GaSettingsService} from './integrationSettings/ga-settings.service';
import {IntegrationInfoViewModel} from '../../../models/viewModels/CreateIntegrationViewModel';
import {RentaApiService} from '../../../services/renta-api.service';
import {
  ScheduleSettingsComponent
} from '../components/internal/integration-settings/schedule-settings/schedule-settings.component';
import {
  IntegrationInfoComponent
} from '../components/internal/integration-settings/integration-info/integration-info.component';
import {AdwordsSettingsService} from './integrationSettings/adwords-settings.service';
import {
  AdwordsSettingsComponent
} from '../components/internal/integration-settings/adwords-settings/adwords-settings.component';
import {GoogleAdsSettingsService} from './integrationSettings/google-ads-settings.service';
import {GoogleAdsSettingsComponent} from '../components/internal/integration-settings/google-ads-settings/google-ads-settings.component';
import {TikTokAdsSettingsComponent} from '../components/internal/integration-settings/tiktok-ads-settings/tiktok-ads-settings.component';
import {TikTokAdsSettingsService} from './integrationSettings/tiktok-ads-settings.service';
import {FbAdsSettingsService} from './integrationSettings/fb-ads-settings.service';
import {
  FbAdsSettingsComponent
} from '../components/internal/integration-settings/fb-ads-settings/fb-ads-settings.component';
import {YandexLogsSettingsService} from './integrationSettings/yandex-logs-settings.service';
import {
  YandexLogsSettingsComponent
} from '../components/internal/integration-settings/yandex-logs-settings/yandex-logs-settings.component';
import {
  MyTargetSettingsComponent
} from '../components/internal/integration-settings/my-target-settings/my-target-settings.component';
import {MyTargetSettingsService} from './integrationSettings/my-target-settings.service';
import {VkSettingsService} from './integrationSettings/vk-settings.service';
import {VkSettingsComponent} from '../components/internal/integration-settings/vk-settings/vk-settings.component';
import {YandexMetricaSettingsService} from './integrationSettings/yandex-metrica-settings.service';
import {
  YandexMetricaSettingsComponent
} from '../components/internal/integration-settings/yandex-metrica/yandex-metrica-settings.component';
import {Dv360SettingsService} from './integrationSettings/dv360-settings.service';
import {
  Dv360SettingsComponent
} from '../components/internal/integration-settings/dv360-settings/dv360-settings.component';
import {YandexDirectSettingsService} from './integrationSettings/yandex-direct-settings.service';
import {YandexDirectComponent} from '../components/internal/integration-settings/yandex-direct/yandex-direct.component';
import {YandexMarketSettingsService} from './integrationSettings/yandex-market-settings.service';
import {
  YandexMarketSettingsComponent
} from '../components/internal/integration-settings/yandex-market-settings/yandex-market-settings.component';
import {AmoCrmSettingsService} from './integrationSettings/amo-crm-settings.service';
import {AmoCrmSettingsComponent } from '../components/internal/integration-settings/amo-crm-settings/amo-crm-settings.component';
import {KommoSettingsService} from './integrationSettings/kommo-settings.service';
import {KommoSettingsComponent} from '../components/internal/integration-settings/kommo-settings/kommo-settings.component';
import {XAdsSettingsService} from './integrationSettings/x-ads-settings.service';
import {XAdsSettingsComponent} from '../components/internal/integration-settings/x-ads-settings/x-ads-settings.component';
import {EsputnikSettingsComponent } from '../components/internal/integration-settings/esputnik-settings/esputnik-settings.component';
import {EsputnikSettingsService} from './integrationSettings/esputnik-settings.service';
import {AmplitudeSettingsService} from './integrationSettings/amplitude-settings.service';
import {
  AmplitudeSettingsComponent
} from '../components/internal/integration-settings/amplitude-settings/amplitude-settings.component';
import {
  ActiveCampaignSettingsComponent
} from '../components/internal/integration-settings/active-campaign-settings/active-campaign-settings.component';
import {ActiveCampaignSettingsService} from './integrationSettings/active-campaign-settings.service';
import {
  BingAdsSettingsComponent
} from '../components/internal/integration-settings/bing-ads-settings/bing-ads-settings.component';
import {BingAdsSettingsService} from './integrationSettings/bing-ads-settings.service';
import {MySqlSettingsService} from './integrationSettings/my-sql-settings.service';
import {
  MySqlSettingsComponent
} from '../components/internal/integration-settings/my-sql-settings/my-sql-settings.component';
import {GetintentSettingsService} from './integrationSettings/getintent-settings.service';
import {
  GetintentSettingsComponent
} from '../components/internal/integration-settings/getintent-settings/getintent-settings.component';
import {PostgreSqlSettingsService} from './integrationSettings/postgre-sql-settings.service';
import {
  PostgreSqlSettingsComponent
} from '../components/internal/integration-settings/postgre-sql-settings/postgre-sql-settings.component';
import {SocialBakersSettingsService} from './integrationSettings/social-bakers-settings.service';
import {
  SocialBakersSettingsComponent
} from '../components/internal/integration-settings/social-bakers-settings/social-bakers-settings.component';
import {
  SourceDestinationSettingsComponent
} from '../components/internal/integration-settings/source-destination-settings/source-destination-settings.component';
import {RentaModalsService} from '../../shared/services/renta-modals.service';
import {
  SalesForceSettingsComponent
} from '../components/internal/integration-settings/sales-force-settings/sales-force-settings.component';
import {SalesForceSettingsService} from './integrationSettings/sales-force-settings.service';
import {
  FilesSettingsComponent
} from '../components/internal/integration-settings/files-settings/files-settings.component';
import {FilesSettingsService} from './integrationSettings/files-settings.service';
import {WebhookAddonSettingsService} from './integrationSettings/webhook-addon-settings.service';
import {
  WebhookAddonSettingsComponent
} from '../components/internal/integration-settings/webhook-addon-settings/webhook-addon-settings.component';
import {
  WarehouseAddonSettingsComponent
} from '../components/internal/integration-settings/warehouse-addon-settings/warehouse-addon-settings.component';
import {WarehouseAddonSettingsService} from './integrationSettings/warehouse-addon-settings.service';
import { FbAdsAddonSettingsService } from './integrationSettings/fb-ads-addon-settings.service';
import {
  FbAdsAddonSettingsComponent
} from '../components/internal/integration-settings/fb-ads-addon-settings/fb-ads-addon-settings.component';

import { BigqueryAddonSettingsService } from './integrationSettings/bigquery-addon-settings.service';
import { JsSdkSettingsService } from './integrationSettings/js-sdk-settings.service';
import {GoogleSheetsSettingsService} from './integrationSettings/google-sheets-settings.service';
import {GoogleSheetsComponent} from '../components/internal/integration-settings/google-sheets-settings/google-sheets.component';
import {IikoWebSettingsService} from './integrationSettings/iiko-web-settings.service';
import {IikoWebSettingsComponent} from '../components/internal/integration-settings/iiko-web-settings/iiko-web-settings.component';
import {IikoSettingsService} from './integrationSettings/iiko-settings.service';
import {IikoSettingsComponent} from '../components/internal/integration-settings/iiko-settings/iiko-settings.component';


@Injectable()
export class SettingsComponentFactoryResolver {
    constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    public readonly rentaApiService: RentaApiService,
    protected readonly rentaModalsService: RentaModalsService,
    ) {
    }

  public getInjector(integrationInfoData: IntegrationInfoViewModel): Injector {
    let settingsService = null;
    let injector = null;

    switch (integrationInfoData.integrationSourceType.integrationType) {
      case IntegrationTypeEnum.criteo:
        settingsService = new CriteoSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: CriteoSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.ga:
        settingsService = new GaSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: GaSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;

      case IntegrationTypeEnum.google_sheets:
        settingsService = new GoogleSheetsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: GoogleSheetsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.yandex_direct:
        settingsService = new YandexDirectSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: YandexDirectSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.google_ads:
        settingsService = new GoogleAdsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: GoogleAdsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.tiktok_ads:
        settingsService = new TikTokAdsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: TikTokAdsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.iikoweb:
        settingsService = new IikoWebSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: IikoWebSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.iiko:
        settingsService = new IikoSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: IikoSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.adwords:
        settingsService = new AdwordsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: AdwordsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.activeCampaign:
        settingsService = new ActiveCampaignSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: ActiveCampaignSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.yandex_metrica_logs_api:
        settingsService = new YandexLogsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: YandexLogsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.facebook_ads:
        settingsService = new FbAdsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: FbAdsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.adform:
      case IntegrationTypeEnum.gaStream:
      case IntegrationTypeEnum.myTarget:
        settingsService = new MyTargetSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: MyTargetSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.vk_ads:
        settingsService = new VkSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: VkSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.x_ads:
        settingsService = new XAdsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: XAdsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.eSputnik:
        settingsService = new EsputnikSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: EsputnikSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.yandex_metrica:
        settingsService = new YandexMetricaSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: YandexMetricaSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.bing:
        settingsService = new BingAdsSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: BingAdsSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.amplitude:
        settingsService = new AmplitudeSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: AmplitudeSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.yandexMarket:
        settingsService = new YandexMarketSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: YandexMarketSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.file:
        settingsService = new FilesSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: FilesSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.socialBakers:
        settingsService = new SocialBakersSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: SocialBakersSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.amocrm:
        settingsService = new AmoCrmSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: AmoCrmSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.kommo:
        settingsService = new KommoSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: KommoSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.dv360:
        settingsService = new Dv360SettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: Dv360SettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.salesforce:
        settingsService = new SalesForceSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: SalesForceSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.clickhouse:
      // TODO: Where is settingsService for clickhouse?

      case IntegrationTypeEnum.snowflake:
      // TODO: Where is settingsService for snowflake?

      case IntegrationTypeEnum.amazon_redshift:
      // TODO: Where is settingsService for amazon_redshift?

      case IntegrationTypeEnum.postgreSql:
        settingsService = new PostgreSqlSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: PostgreSqlSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.mySql:
        settingsService = new MySqlSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: MySqlSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.getintent:
        settingsService = new GetintentSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        injector = Injector.create({
          providers: [
            {
              provide: GetintentSettingsService,
              useValue: settingsService
            },
            {
              provide: ETL_SETTINGS_SERVICE,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
        break;
      case IntegrationTypeEnum.javascript_sdk:

       // TODO : OLD  injector = this.getAddonInjector(integrationInfoData)

        settingsService = new JsSdkSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);
        injector = Injector.create({
          providers: [
            {
              provide: JsSdkSettingsService,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.getAddonInjector(integrationInfoData)
        });
        break;
      default:
    }
    return injector;
  }

  public getAddonSettingsComponentFactory(integrationType: IntegrationTypeEnum): ComponentFactory<any> {
    switch (integrationType) {
      case IntegrationTypeEnum.webHook:
        return this.componentFactoryResolver.resolveComponentFactory(WebhookAddonSettingsComponent);
      case IntegrationTypeEnum.bigQuery:
      case IntegrationTypeEnum.clickhouse:
        return this.componentFactoryResolver.resolveComponentFactory(WarehouseAddonSettingsComponent);
      case IntegrationTypeEnum.facebook_ads:
        return this.componentFactoryResolver.resolveComponentFactory(FbAdsAddonSettingsComponent);
      default:
        return null;
    }
  }

  public getParametersComponentFactory(integrationType: IntegrationTypeEnum): ComponentFactory<any> {
    switch (integrationType) {
      case IntegrationTypeEnum.criteo:
        return this.componentFactoryResolver.resolveComponentFactory(CriteoSettingsComponent);
      case IntegrationTypeEnum.ga:
        return this.componentFactoryResolver.resolveComponentFactory(GaSettingsComponent);
      case IntegrationTypeEnum.google_sheets:
        return this.componentFactoryResolver.resolveComponentFactory(GoogleSheetsComponent);
      case IntegrationTypeEnum.yandex_direct:
        return this.componentFactoryResolver.resolveComponentFactory(YandexDirectComponent);
      case IntegrationTypeEnum.google_ads:
        return this.componentFactoryResolver.resolveComponentFactory(GoogleAdsSettingsComponent);
      case IntegrationTypeEnum.tiktok_ads:
        return this.componentFactoryResolver.resolveComponentFactory(TikTokAdsSettingsComponent);
      case IntegrationTypeEnum.iikoweb:
        return this.componentFactoryResolver.resolveComponentFactory(IikoWebSettingsComponent);
      case IntegrationTypeEnum.iiko:
        return this.componentFactoryResolver.resolveComponentFactory(IikoSettingsComponent);
      case IntegrationTypeEnum.adwords:
        return this.componentFactoryResolver.resolveComponentFactory(AdwordsSettingsComponent);
      case IntegrationTypeEnum.activeCampaign:
        return this.componentFactoryResolver.resolveComponentFactory(ActiveCampaignSettingsComponent);
      case IntegrationTypeEnum.yandex_metrica_logs_api:
        return this.componentFactoryResolver.resolveComponentFactory(YandexLogsSettingsComponent);
      case IntegrationTypeEnum.facebook_ads:
        return this.componentFactoryResolver.resolveComponentFactory(FbAdsSettingsComponent);
      case IntegrationTypeEnum.adform:
      case IntegrationTypeEnum.gaStream:
      case IntegrationTypeEnum.myTarget:
        return this.componentFactoryResolver.resolveComponentFactory(MyTargetSettingsComponent);
      case IntegrationTypeEnum.vk_ads:
        return this.componentFactoryResolver.resolveComponentFactory(VkSettingsComponent);
      case IntegrationTypeEnum.x_ads:
        return this.componentFactoryResolver.resolveComponentFactory(XAdsSettingsComponent);
      case IntegrationTypeEnum.eSputnik:
        return this.componentFactoryResolver.resolveComponentFactory(EsputnikSettingsComponent);
      case IntegrationTypeEnum.yandex_metrica:
        return this.componentFactoryResolver.resolveComponentFactory(YandexMetricaSettingsComponent);
      case IntegrationTypeEnum.bing:
        return this.componentFactoryResolver.resolveComponentFactory(BingAdsSettingsComponent);
      case IntegrationTypeEnum.amplitude:
        return this.componentFactoryResolver.resolveComponentFactory(AmplitudeSettingsComponent);
      case IntegrationTypeEnum.yandexMarket:
        return this.componentFactoryResolver.resolveComponentFactory(YandexMarketSettingsComponent);
      case IntegrationTypeEnum.file:
        return this.componentFactoryResolver.resolveComponentFactory(FilesSettingsComponent);
      case IntegrationTypeEnum.socialBakers:
        return this.componentFactoryResolver.resolveComponentFactory(SocialBakersSettingsComponent);
      case IntegrationTypeEnum.amocrm:
        return this.componentFactoryResolver.resolveComponentFactory(AmoCrmSettingsComponent);
      case IntegrationTypeEnum.kommo:
        return this.componentFactoryResolver.resolveComponentFactory(KommoSettingsComponent);
      case IntegrationTypeEnum.dv360:
        return this.componentFactoryResolver.resolveComponentFactory(Dv360SettingsComponent);
      case IntegrationTypeEnum.salesforce:
        return this.componentFactoryResolver.resolveComponentFactory(SalesForceSettingsComponent);
      case IntegrationTypeEnum.bigQuery:
      case IntegrationTypeEnum.googleCloudStorage:
      case IntegrationTypeEnum.clickhouse:
      case IntegrationTypeEnum.snowflake:
      case IntegrationTypeEnum.amazon_redshift:
      case IntegrationTypeEnum.postgreSql:
        return this.componentFactoryResolver.resolveComponentFactory(PostgreSqlSettingsComponent);
      case IntegrationTypeEnum.mySql:
        return this.componentFactoryResolver.resolveComponentFactory(MySqlSettingsComponent);
      case IntegrationTypeEnum.getintent:
        return this.componentFactoryResolver.resolveComponentFactory(GetintentSettingsComponent);
      default:
        return null;
    }
  }

  public getSourceDestinationFactory(): ComponentFactory<any> {
    return this.componentFactoryResolver.resolveComponentFactory(SourceDestinationSettingsComponent);
  }

  public getScheduleComponentFactory(integrationType: IntegrationTypeEnum): ComponentFactory<any> {
    switch (integrationType) {
      case IntegrationTypeEnum.criteo:
      case IntegrationTypeEnum.ga:
      case IntegrationTypeEnum.google_sheets:
      case IntegrationTypeEnum.yandex_direct:
      case IntegrationTypeEnum.adwords:
      case IntegrationTypeEnum.google_ads:
      case IntegrationTypeEnum.tiktok_ads:
      case IntegrationTypeEnum.iikoweb:
      case IntegrationTypeEnum.iiko:
      case IntegrationTypeEnum.activeCampaign:
      case IntegrationTypeEnum.yandex_metrica_logs_api:
      case IntegrationTypeEnum.facebook_ads:
      case IntegrationTypeEnum.adform:
      case IntegrationTypeEnum.gaStream:
      case IntegrationTypeEnum.myTarget:
      case IntegrationTypeEnum.vk_ads:
      case IntegrationTypeEnum.x_ads:
      case IntegrationTypeEnum.eSputnik:
      case IntegrationTypeEnum.yandex_metrica:
      case IntegrationTypeEnum.bing:
      case IntegrationTypeEnum.amplitude:
      case IntegrationTypeEnum.yandexMarket:
      case IntegrationTypeEnum.file:
      case IntegrationTypeEnum.socialBakers:
      case IntegrationTypeEnum.amocrm:
      case IntegrationTypeEnum.kommo:
      case IntegrationTypeEnum.dv360:
      case IntegrationTypeEnum.salesforce:
      case IntegrationTypeEnum.bigQuery:
      case IntegrationTypeEnum.googleCloudStorage:
      case IntegrationTypeEnum.clickhouse:
      case IntegrationTypeEnum.snowflake:
      case IntegrationTypeEnum.amazon_redshift:
      case IntegrationTypeEnum.postgreSql:
      case IntegrationTypeEnum.mySql:
      default:
        return this.componentFactoryResolver.resolveComponentFactory(ScheduleSettingsComponent);
    }
  }

  public getCreateIntegrationInfo(): ComponentFactory<any> {
    return this.componentFactoryResolver.resolveComponentFactory(IntegrationInfoComponent);
  }

  private getAddonInjector(integrationInfoData: IntegrationInfoViewModel): Injector {
    let settingsService = null;
    switch (integrationInfoData.integrationDestinationType.integrationType) {
      case IntegrationTypeEnum.webHook:
       settingsService = new WebhookAddonSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);
       return Injector.create({
          providers: [
            {
              provide: WebhookAddonSettingsService,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
      case IntegrationTypeEnum.bigQuery:
        settingsService = new BigqueryAddonSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        return Injector.create({
          providers: [
            {
              provide: BigqueryAddonSettingsService,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });

      case IntegrationTypeEnum.clickhouse:
        settingsService = new WarehouseAddonSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        return Injector.create({
          providers: [
            {
              provide: WarehouseAddonSettingsService,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
      case IntegrationTypeEnum.facebook_ads: {
        settingsService = new FbAdsAddonSettingsService(integrationInfoData, this.rentaApiService, this.rentaModalsService);

        return Injector.create({
          providers: [
            {
              provide: FbAdsAddonSettingsService,
              useValue: settingsService
            },
            {
              provide: SETTINGS_SERVICE_BASE,
              useValue: settingsService
            }
          ],
          parent: this.injector
        });
      }
      default:
        return null;
    }
  }
}
