import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef, Injector,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { ModalDialogTypeEnum } from '../../../../models/ModalDialogTypeEnum';
import { IntegrationTypeEnum } from '../../../../../../models/common/IntegrationTypeEnum';
import { CriteoAuthComponent } from '../criteo-auth/criteo-auth.component';
import { IntegrationToken } from '../../../../../../models/Integration/IntegrationToken';
import { ClickhouseAuthComponent } from '../clickhouse-auth/clickhouse-auth.component';
import { SnowflakeAuthComponent } from '../snowflake-auth/snowflake-auth.component';
import { TokensServiceService } from '../../../../../../services/tokens-service.service';
import { ChooseStorageModalComponent } from '../choose-storage-modal/choose-storage-modal.component';
import { AmoCrmAuthComponent } from '../amo-crm-auth/amo-crm-auth.component';
import { XAdsAuthComponent } from '../x-ads-auth/x-ads-auth.component';
import { EsputnikAuthComponent } from '../esputnik/esputnik-auth.component';
import { AmplitudeAuthComponent } from '../amplitude-auth/amplitude-auth.component';
import { ActiveCampaignAuthComponent } from '../active-campaign-auth/active-campaign-auth.component';
import { MySqlAuthComponent } from '../my-sql-auth/my-sql-auth.component';
import * as _ from 'lodash';
import { GetintentAuthComponent } from '../getintent-auth/getintent-auth.component';
import { IikowebAuthComponent } from '../iikoweb-auth/iikoweb-auth.component';
import { IikocloudAuthComponent } from '../iikocloud-auth/iikocloud-auth.component';
import { PostgreSqlAuthComponent } from '../postgre-sql-auth/postgre-sql-auth.component';
import { IntegrationSourceModel } from '../../../../../../models/Integration/IntegrationSourceModel';
import { FilesAuthComponent } from '../files-auth/files-auth.component';
import { WebHookModalComponent } from '../web-hook-modal/web-hook-modal.component';
import { MssqlAuthComponent } from '../mssql-auth/mssql-auth.component';
import { AmazonS3AuthComponent } from '../amazon-s3-auth/amazon-s3-auth.component';
import { IntegrationRefreshService } from '../../../../../integrations/services/integration-refresh.service';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-base-integration-modal',
  templateUrl: './base-integration-modal.component.html',
  styleUrls: ['./base-integration-modal.component.css'],
})
export class BaseIntegrationModalComponent implements OnInit, AfterViewInit {
  @ViewChild('integrationBody', { read: ViewContainerRef })
  public integrationBody: ViewContainerRef;
  public integrationBodyWithSubmit: boolean = false;
  public closable: boolean;
  public faTimes: IconDefinition = faTimes;
  public integration: IntegrationSourceModel;
  public integrationToken: IntegrationToken;
  public dialogType: ModalDialogTypeEnum;
  public dialogTitle: string;
  public dialogTitleInfo: string;
  public submitLabel: string;
  public errorDelete: string;
  public loading: boolean;
  private integrationBodyComponentRef: ComponentRef<any>;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly tokenService: TokensServiceService,
    private readonly injector: Injector,
    private cdr: ChangeDetectorRef,
    private integrationRefreshService: IntegrationRefreshService
  ) {
    this.closable = config.closable;
    this.integration = this.config.data.integrationInfo as IntegrationSourceModel;
    this.dialogType = this.config.data.dialogType as ModalDialogTypeEnum;
    this.submitLabel = 'ADD';
    if (this.dialogType === ModalDialogTypeEnum.DeleteToken) {
      this.integrationToken = this.config.data.integrationToken as IntegrationToken;
      this.submitLabel = 'DELETE';
    }
  }

  public ngOnInit(): void {
    this.loading = false;
    switch (this.dialogType) {
      case ModalDialogTypeEnum.CreateToken:
        this.dialogTitle = `New ${this.integration.title} account`;
        this.dialogTitleInfo = `Enter your credentials to add account`;
        break;
      case ModalDialogTypeEnum.DeleteToken:
        this.dialogTitle = `Delete account`;
        this.dialogTitleInfo = `Are you sure you want to delete \n“${this.integrationToken.title}” account?`;
        break;
      case ModalDialogTypeEnum.ChooseDb:
        this.dialogTitle = `${this.integration.title} storage`;
        this.dialogTitleInfo = `Please select the project to store data`;
        break;
    }
  }

  public submit(data: any): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const handleResponse = (res: string): void => {
      this.onResponse(res);
      this.integrationRefreshService.emitRefreshEvent();
    };

    if (this.dialogType === ModalDialogTypeEnum.DeleteToken) {
      this.tokenService
        .removeToken(this.integration.integrationType, this.integrationToken.id)
        .subscribe(handleResponse);
      return;
    }

    const body: any = data ? data : this.getSubmitBody();
    this.integrationBodyComponentRef.instance.loading = this.loading;

    const tokenObservable = this.tokenService.createNewToken(this.integration.integrationType, body)
      .pipe(
        tap(handleResponse),
        delay(1000)
      );

    tokenObservable.subscribe();
  }


  public cancel(): void {
    if (this.loading) {
      return;
    }

    this.ref.close(false);
  }

  public ngAfterViewInit(): void {
    if (this.dialogType === ModalDialogTypeEnum.DeleteToken) {
      return;
    }

    this.createChild();
    this.detectChanges();
  }

  private detectChanges(): void {
    this.cdr.detectChanges();
    const newDialogTitleInfo = this.integrationBodyComponentRef.instance.dialogTitleInfo;
    this.dialogTitleInfo = !_.isNil(newDialogTitleInfo) && newDialogTitleInfo.length > 0 ? newDialogTitleInfo : this.dialogTitleInfo;
  }

  private onResponse(result: string): void {
    this.loading = false;
    if (result === null) {
      this.ref.close(true);
      return;
    }

    if (this.dialogType === ModalDialogTypeEnum.DeleteToken){
      this.errorDelete = result;
    }

    this.integrationBodyComponentRef.instance.loading = this.loading;
    this.integrationBodyComponentRef.instance.error = result;
  }

  private createChild(): void {
    let integrationBody = null;
    let additionalInfo = null;

    switch (this.integration.integrationType) {
      case IntegrationTypeEnum.yandex_direct:
      case IntegrationTypeEnum.adwords:
      case IntegrationTypeEnum.ga:
      case IntegrationTypeEnum.activeCampaign:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(ActiveCampaignAuthComponent);
        break;
      case IntegrationTypeEnum.mySql:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(MySqlAuthComponent);
        break;
      case IntegrationTypeEnum.postgreSql:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(PostgreSqlAuthComponent);
        break;
      case IntegrationTypeEnum.salesforce:
      case IntegrationTypeEnum.bigQuery:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(ChooseStorageModalComponent);
        additionalInfo = {
          id: this.config.data.id,
          integrationType: this.integration.integrationType,
        };
        this.integrationBodyWithSubmit = true;
        break;
      case IntegrationTypeEnum.yandex_metrica_logs_api:
      case IntegrationTypeEnum.facebook_ads:
      case IntegrationTypeEnum.adform:
      case IntegrationTypeEnum.gaStream:
      case IntegrationTypeEnum.myTarget:
      case IntegrationTypeEnum.vk_ads:
      case IntegrationTypeEnum.x_ads:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(XAdsAuthComponent);
        break;
      case IntegrationTypeEnum.eSputnik:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(EsputnikAuthComponent);
        break;
      case IntegrationTypeEnum.yandex_metrica:
      case IntegrationTypeEnum.bing:
      case IntegrationTypeEnum.amplitude:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(AmplitudeAuthComponent);
        break;
      case IntegrationTypeEnum.yandexMarket:
      case IntegrationTypeEnum.file:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(FilesAuthComponent);
        break;
      case IntegrationTypeEnum.socialBakers:
      case IntegrationTypeEnum.amocrm:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(AmoCrmAuthComponent);
        break;
      case IntegrationTypeEnum.dv360:
        return null;
      case IntegrationTypeEnum.criteo:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(CriteoAuthComponent);
        break;
      case IntegrationTypeEnum.clickhouse:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(ClickhouseAuthComponent);
        break;
      case IntegrationTypeEnum.snowflake:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(SnowflakeAuthComponent);
        break;
      case IntegrationTypeEnum.sql_server:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(MssqlAuthComponent);
        break;
      case IntegrationTypeEnum.getintent:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(GetintentAuthComponent);
        break;
      case IntegrationTypeEnum.iikoweb:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(IikowebAuthComponent);
        break;
      case IntegrationTypeEnum.iiko:
          integrationBody = this.componentFactoryResolver.resolveComponentFactory(IikocloudAuthComponent);
          break;
      case IntegrationTypeEnum.webHook:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(WebHookModalComponent);
        break;
      case IntegrationTypeEnum.amazon_s3:
        integrationBody = this.componentFactoryResolver.resolveComponentFactory(AmazonS3AuthComponent);
        break;
    }

    if (integrationBody !== null) {
      this.integrationBodyComponentRef = this.integrationBody.createComponent(integrationBody, 0, this.injector);

      if (additionalInfo !== null) {
        this.integrationBodyComponentRef.instance.additionalInfo = additionalInfo;
      }

      if (this.integrationBodyWithSubmit) {
        this.integrationBodyComponentRef.instance.OnSubmit.subscribe((res: any): void => this.submit(res));
      }
    }
  }

  private getSubmitBody(): any {
    const result: any = {};
    switch (this.integration.integrationType) {
      case IntegrationTypeEnum.yandex_direct:
      case IntegrationTypeEnum.adwords:
      case IntegrationTypeEnum.ga:
      case IntegrationTypeEnum.activeCampaign:
        result.apiUrl = this.integrationBodyComponentRef.instance.apiUrl;
        result.apiKey = this.integrationBodyComponentRef.instance.apiKey;
        break;
      case IntegrationTypeEnum.mySql:
        result.ip = this.integrationBodyComponentRef.instance.ip;
        result.port = this.integrationBodyComponentRef.instance.port;
        result.login = this.integrationBodyComponentRef.instance.login;
        result.password = this.integrationBodyComponentRef.instance.password;
        break;
      case IntegrationTypeEnum.postgreSql:
        result.ip = this.integrationBodyComponentRef.instance.ip;
        result.port = this.integrationBodyComponentRef.instance.port;
        result.login = this.integrationBodyComponentRef.instance.login;
        result.password = this.integrationBodyComponentRef.instance.password;
        result.DbName = this.integrationBodyComponentRef.instance.databaseName;
        result.useSsl = this.integrationBodyComponentRef.instance.useSsl;
        break;
      case IntegrationTypeEnum.salesforce:
      case IntegrationTypeEnum.yandex_metrica_logs_api:
      case IntegrationTypeEnum.facebook_ads:
      case IntegrationTypeEnum.adform:
      case IntegrationTypeEnum.gaStream:
      case IntegrationTypeEnum.myTarget:
      case IntegrationTypeEnum.vk_ads:
      case IntegrationTypeEnum.x_ads:
        result.siteName = this.integrationBodyComponentRef.instance.siteName;
        result.siteId = this.integrationBodyComponentRef.instance.siteId;
        result.clientApiId = this.integrationBodyComponentRef.instance.clientApiId;
        break;
      case IntegrationTypeEnum.eSputnik:
        result.email = this.integrationBodyComponentRef.instance.email;
        result.password = this.integrationBodyComponentRef.instance.password;
        break;
      case IntegrationTypeEnum.yandex_metrica:
      case IntegrationTypeEnum.bing:
      case IntegrationTypeEnum.amplitude:
        result.spaceName = this.integrationBodyComponentRef.instance.spaceName;
        result.apiKey = this.integrationBodyComponentRef.instance.apiKey;
        result.apiSecret = this.integrationBodyComponentRef.instance.apiSecret;
        break;
      case IntegrationTypeEnum.yandexMarket:
      case IntegrationTypeEnum.file:
        const { fileForm } = this.integrationBodyComponentRef.instance;
        const {
          name,
          url,
          separator,
          typeofAuth,
          login,
          password
        } = fileForm.value;

        result.fileName = name;
        result.fileUrl = url;
        result.fileType = separator
        result.authorizationType = typeofAuth;
        result.authorizationData = {
         login,
         password
        }
        break;
      case IntegrationTypeEnum.socialBakers:
      case IntegrationTypeEnum.amocrm:
        result.email = this.integrationBodyComponentRef.instance.email;
        result.apiKey = this.integrationBodyComponentRef.instance.apiKey;
        result.apiUrl = this.integrationBodyComponentRef.instance.apiUrl;
        break;
      case IntegrationTypeEnum.dv360:
        return null;
      case IntegrationTypeEnum.criteo:
        result.name = this.integrationBodyComponentRef.instance.name;
        result.clientId = this.integrationBodyComponentRef.instance.clientId;
        result.clientSecret = this.integrationBodyComponentRef.instance.clientSecret;
        break;
      case IntegrationTypeEnum.clickhouse:
        result.Port = this.integrationBodyComponentRef.instance.Port;
        result.User = this.integrationBodyComponentRef.instance.Login;
        result.Host = this.integrationBodyComponentRef.instance.ServerName;
        result.Password = this.integrationBodyComponentRef.instance.DatabasePassword;
        result.DbName = this.integrationBodyComponentRef.instance.DatabaseName;
        break;
      case IntegrationTypeEnum.snowflake:
        result.Warehouse = this.integrationBodyComponentRef.instance.Warehouse;
        result.Schema = this.integrationBodyComponentRef.instance.Schema;
        result.User = this.integrationBodyComponentRef.instance.Login;
        result.Host = this.integrationBodyComponentRef.instance.ServerName;
        result.Password = this.integrationBodyComponentRef.instance.DatabasePassword;
        result.DbName = this.integrationBodyComponentRef.instance.DatabaseName;
        break;
      case IntegrationTypeEnum.amazon_s3:
        result.AwsAccessKey = this.integrationBodyComponentRef.instance.AwsAccessKey;
        result.AwsSecretAccessKey = this.integrationBodyComponentRef.instance.AwsSecretAccessKey;
        result.Region = this.integrationBodyComponentRef.instance.Region;
        result.BucketName = this.integrationBodyComponentRef.instance.BucketName;
        break;
      case IntegrationTypeEnum.sql_server:
        result.ip = this.integrationBodyComponentRef.instance.ip;
        result.port = this.integrationBodyComponentRef.instance.port;
        result.login = this.integrationBodyComponentRef.instance.login;
        result.password = this.integrationBodyComponentRef.instance.password;
        result.DbName = this.integrationBodyComponentRef.instance.databaseName;
        result.useSsl = this.integrationBodyComponentRef.instance.useSsl;
        break;
      case IntegrationTypeEnum.getintent:
        result.name = this.integrationBodyComponentRef.instance.accountName;
        result.apiToken = this.integrationBodyComponentRef.instance.apiKey;
        break;
      case IntegrationTypeEnum.iikoweb:
        result.api_host = this.integrationBodyComponentRef.instance.host;
        result.api_login = this.integrationBodyComponentRef.instance.login;
        result.api_password = this.integrationBodyComponentRef.instance.password;
        break;
      case IntegrationTypeEnum.iiko:
        result.name = this.integrationBodyComponentRef.instance.sourceName;
        result.api_login = this.integrationBodyComponentRef.instance.login;
        break;
      case IntegrationTypeEnum.webHook:
        result.name = this.integrationBodyComponentRef.instance.name;
        break;
    }
    return result;
  }
}
